.popoverInfo {
  position: relative;
  max-width: 342px;
  padding-right: 18px;
  font-size: 16px;

  > span {
    color: var(--app-text-color);
  }

  .closeIcon {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
  }
}

