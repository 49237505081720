.copyContainer {
  margin-top: 20px;
  margin-bottom: 24px;

  border: 1px solid var(--app-text-30-color);
  border-radius: 5px;
  height: 36px;
  display: flex;
  align-items: center;

  > .url {
    width: 100%;
    padding: 0 10px;
    color: var(--app-text-60-color);

    display: -webkit-box;
    overflow: hidden;
    word-break: break-word;
    white-space: nowrap;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
  }

  > .copy {
    margin: 5px;
    width: 25px;
    height: 25px;
    border: 0;
    background: transparent;
    cursor: pointer;
  }
}

.container {
  padding: 20px 24px;

  > .shareTitle {
    font-size: 20px;
    font-weight: 500;
  }

  > .shareContainer {
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

